import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('booking', [
      'buyerAttribution',
      'canEditPlayers',
      'canEditSettings',
      'hasBuyerLeft',
      'iAmInvited',
      'iAmTheBuyer',
      'myAttribution',
      'myContactId',
      'myUserId',
      'paidAttributions',
      'unpaidAttributions',
    ]),
  },
};
